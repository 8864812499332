import { useCallback, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.scss';
import { getCookie, setCookie } from './utilities/cookies';
import PlayView from './views/PlayView/PlayView';
import QuestionsView from './views/QuestionsView';
import RegisterView from './views/RegisterView';
import LeaderboardView from './views/LeaderboardView';
import ReactGA from 'react-ga4';


export const CODE_COOKIE = "swipe_code"
export const EMAIL_COOKIE = "swipe_user_email"
export const NICKNAME_COOKIE = "swipe_user_nickname"

ReactGA.initialize(process.env.REACT_APP_GA4_GID);

function App() {
  const [codeCookie, setCodeCookie] = useState(getCookie(CODE_COOKIE));
  const [emailCookie, setEmailCookie] = useState(getCookie(EMAIL_COOKIE));
  const [nicknameCookie, setNicknameCookie] = useState(getCookie(NICKNAME_COOKIE));
  const [swipeGameId, setSwipeGameId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [score, setScore] = useState(0);

  const updateCookie = useCallback(
    (cookieName, newVal) => {
      setCookie(cookieName, newVal);

      if (cookieName === CODE_COOKIE) {
        setCodeCookie(newVal);
      } else if (cookieName === EMAIL_COOKIE) {
        setEmailCookie(newVal);
      } else {
        setNicknameCookie(newVal);
      }
    },
    [setCodeCookie, setEmailCookie, setNicknameCookie]
  );

  const accumScore = useCallback(
    (addScore) => {
      setScore(prevScore => {
        return prevScore + addScore;
      });
    },
    [setScore]
  );

  const reset = useCallback(
    () => {
      setScore(0);
      setQuestions([]);
      setSwipeGameId(null);
    },
    [setScore, setQuestions, setSwipeGameId]
  )

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<PlayView  code={codeCookie}
              updateCookie={updateCookie} setSwipeGameId={setSwipeGameId} />} />
        <Route path="/juego"
          element={
            <QuestionsView
              score={score}
              accumScore={accumScore}
              swipeGameId={swipeGameId}
              resetMatch={reset} />
          }
        />
        <Route path="/registro"
          element={
            <RegisterView questions={questions}
              email={emailCookie}
              nickname={nicknameCookie}
              updateCookie={updateCookie}
              score={score}
              swipeGameId={swipeGameId}
              resetMatch={reset} />
          }
        />
        <Route path="/leaderboard"
          element={
            <LeaderboardView nickname={nicknameCookie}
              email={emailCookie}
              resetMatch={reset} />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
