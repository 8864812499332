import axios from 'axios';
import { isNullOrEmpty } from '../cookies';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || 'http://localhost:8000/swipe_gol',
})


export function generateSwipeGame(code) {
  if (isNullOrEmpty(code)) {
    return instance.get('/swipe_code/new');
  }
  
  return instance.get('/swipe_code/new', { params: { swipe_code_uuid: code } });
}

export function register(swipeGameUuid, score, email, nickname) {
  return instance.post('/swipe_code/new/' + swipeGameUuid, { score, email, nickname });
}
