import SwipeAnswer from "./SwipeAnswer";
import darkTimerImg from '../assets/images/dark-timer.svg';
import { SPECIAL_QUESTION_1_NUM } from "../utilities/constants";


function AskQuestion({ timer, questionText, questionNum, swipedNo, swipedYes, showLoader }) {
  return (
    <div className="d-flex flex-column z-index-2 h-75 w-95">

      <div className="gradient-rounded-border-special rect-scooped-corners mx-4 my-2 h-100 shadow">
        <div className="box w-100 h-100">
          <div className="top-shine"></div>
          <div className="corner nw special"></div>
          <div className="corner ne special"></div>
          <div className="corner sw special"></div>
          <div className="corner se special"></div>
          <div className="d-flex flex-column justify-content-between align-items-center flex-grow-1 h-100 bg-special-question box-content">
            <div className="d-flex justify-content-center align-items-center fw-semibold badge-status-timer-special w-25 py-2 px-5 mt-3">
              <img src={darkTimerImg} alt="Tiempo" />
              <span className="text-black fw-bold ms-1">00:{timer === 10 ? timer : `0${timer}`}</span>
            </div>
            <div className="question-text text-center text-white">{questionText}</div>
            <div className="question-num-container question-num-container-special py-3">
              <div>PREGUNTA ESPECIAL {questionNum === SPECIAL_QUESTION_1_NUM ? 'X2' : 'X3'}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-2">
        {!showLoader && <SwipeAnswer onSwipeNo={swipedNo} onSwipeYes={swipedYes} />}
        {showLoader && (
          <div className="spinner-border text-light custom-spinner" role="status">
            <span className="visually-hidden">Enviando respuesta...</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default AskQuestion;
