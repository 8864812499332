import { useState, useEffect } from 'react';
import sadFace from '../assets/images/sad-face.svg';
import football from '../assets/images/football-resized.png';


export const MAX_TIMER = 2;

function IncorrectAnswer({ questionNum, totalQuestions, questionText, proceed }) {
  const [feedbackTimer, setFeedbackTimer] = useState(MAX_TIMER);

  useEffect(() => {
    const countAux = feedbackTimer > 0 && setInterval(() => setFeedbackTimer((lastTimer) => lastTimer - 1), 1000);

    if (feedbackTimer === 0) {
      clearInterval(countAux);
      proceed();
    }
    return () => clearInterval(countAux);
  }, [feedbackTimer, proceed]);

  return (
    <div className="d-flex flex-column z-index-2 h-75 w-95">

      <div className="gradient-rounded-border-red rect-scooped-corners mx-4 my-2 h-100 shadow">
        <div className="box w-100 h-100">
          <div className="top-shine"></div>
          <div className="corner nw red"></div>
          <div className="corner ne red"></div>
          <div className="corner sw red"></div>
          <div className="corner se red"></div>
          <div className="d-flex flex-column justify-content-between align-items-center h-100 bg-white box-content">
            <div className="d-flex justify-content-center align-items-center fw-semibold badge-status-incorrect mw-50 py-2 px-3 mt-3">
              <img src={sadFace} alt="Incorrecto" />
              <span className="text-white fw-bold ms-1">Incorrecto</span>
            </div>
            <div className="question-text question-text-incorrect text-center">{questionText}</div>
            <div className="question-num-container question-num-container-incorrect">
              <div>Pregunta</div>
              <div className="mb-1">
                <span className="question-current">{questionNum}</span>/<span>{totalQuestions}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="question-answer mt-2">
        <img src={football} alt="Balón" className="swipe-ball" />
      </div>
    </div>
  );
}

export default IncorrectAnswer;
