const GTAG_CONFIG = {
  new_game: 'NEW_GAME',
  answered_all: 'ANSWERED_ALL',
  register_score: 'REGISTER_SCORE',
  answered_correctly: 'ANSWERED_CORRECTLY',
  answered_incorrectly: 'ANSWERED_INCORRECTLY',
  new_code: 'NEW_CODE'
}

export default GTAG_CONFIG
