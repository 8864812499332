import { EMAIL_COOKIE, NICKNAME_COOKIE } from "../App";
import { isNullOrEmpty } from "../utilities/cookies";
import emailIcon from  '../assets/images/email-icon.svg';
import smiley from  '../assets/images/smiley.svg';
import { register } from "../utilities/endpoints/swipe_code_endpoints";
import { useNavigate } from "react-router-dom";
import Prize from "../components/Prize";
import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import EventTags from '../utilities/google_analytics_event_tags';
import { isGamesActive } from "../utilities/activeness";


function RegisterView({ email, nickname, updateCookie, score, resetMatch, swipeGameId }) {
  const navigate = useNavigate();
  const [inputErrorMsg, setInputErrorMsg] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formNickname, setFormNickname] = useState('');

  const changeEmail = (e) => {
    updateCookie(EMAIL_COOKIE, e.target.value);
    setFormEmail(e.target.value);
  }

  const changeNickname = (e) => {
    updateCookie(NICKNAME_COOKIE, e.target.value);
    setFormNickname(e.target.value);
  }

  const registerUser = () => {
    if(isNullOrEmpty(swipeGameId)) {
      alert("Inicia un nuevo juego para registrar la puntuación.");
      return;
    }

    if (!isNullOrEmpty(formEmail) && !isNullOrEmpty(formNickname)) {
      register(swipeGameId, score, formEmail, formNickname).then(_resp => {
        ReactGA.event(EventTags.register_score)
        navigate('/leaderboard');
      }).catch(err => {
        console.log(err);
        alert('Ocurrió un error, intenta más tarde');
      })
    } else {
      setInputErrorMsg('Llena ambos campos para registrarte');
    }
  }

  const newGame = () => {
    resetMatch()
    navigate('/')
  }

  useEffect(() => {
    if (!isGamesActive()) {
      navigate('/');
    }
  })

  return (
    <div className="h-100 register register-bg">
      <div className="h-100 d-flex justify-content-center">
        <div className="d-flex flex-column justify-content-between align-items-center text-center text-white z-index-2 my-5 w-75">
          <div className="header fw-semibold fs-4">Registrate y juega para ganar</div>
          <div className="fw-semibold">Premio del día</div>
          <Prize widthClass="w-100 w-md-50" />
          <div className="d-flex justify-content-between align-items-center w-90 w-md-50">
            <div>Tu puntuación</div>
            <div className="register-score">{score} pts.</div>
          </div>
          <div className="register-inputs">
            <div className="input-group register-top-input">
              <span className="input-group-text icon" id="email">
                <img src={emailIcon} alt="Correo" />
              </span>
              <input value={formEmail}
                onChange={changeEmail}
                type="text"
                className="form-control form-field"
                placeholder="Email"
                aria-label="email"
                aria-describedby="email" />
            </div>
            <div className="input-group register-bottom-input">
              <span className="input-group-text icon" id="nickname">
                <img src={smiley} alt="Tu apodo" />
              </span>
              <input value={formNickname}
                onChange={changeNickname}
                type="text"
                className="form-control form-field"
                placeholder="Tu apodo"
                aria-label="apodo"
                aria-describedby="nickname" />
            </div>
          </div>

          {inputErrorMsg.length > 0 && (
            <div className="z-index-2 text-white">
              {inputErrorMsg}
            </div>
          )}

          <div className="mb-2 mb-sm-5">
            <div className="swipe-btn swipe-yellow-btn mb-4" onClick={registerUser}>
              <div className="inner text-uppercase">REGÍSTRATE Y GANA</div>
              <div className="bottom-shine"></div>
            </div>

            <div className="swipe-btn swipe-black-btn text-uppercase" onClick={newGame}>
              Volver a jugar
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterView
