import { useEffect, useRef, useState, useCallback } from 'react';
import football from '../assets/images/football-resized.png';
import threeArrows from '../assets/images/three-arrows.svg';
import noAnswer from '../assets/images/no.svg';
import yesAnswer from '../assets/images/si.svg';
import noSpecialAnswer from '../assets/images/no-special.svg';
import yesSpecialAnswer from '../assets/images/si-special.svg';


const SWIPED_NO = 'no';
const SWIPED_YES = 'yes';

function SwipeAnswer({ onSwipeNo, onSwipeYes, isSpecial = false, horizontalMarginClass = 'mx-2' }) {
  const noDivRef = useRef();
  const yesDivRef = useRef();
  const footballIconRef = useRef();
  const [originalCoords, setOriginalCoords] = useState(null);
  const [noCoords, setNoCoords] = useState(null);
  const [yesCoords, setYesCoords] = useState(null);
  const [xDownCoord, setXDownCoord] = useState(null);
  const [xMoveDiff, setXMoveDiff] = useState(0);
  const [swipeAnswer, setSwipeAnswer] = useState(null);
  const [wasMousePressed, setWasMousePressed] = useState(false);

  const handleMouseDown = (evt) => {
    evt.preventDefault();

    if (evt.target.id === footballIconRef.current.id) {
      setXDownCoord(evt.pageX);
      setWasMousePressed(true);
    }
  }

  const handleTouchStart = (evt) => {
    const firstTouch = evt.touches[0];
    setXDownCoord(firstTouch.clientX);
  }
  
  const handleMouseMove = (evt) => {
    evt.preventDefault();
    if (xDownCoord === null || !wasMousePressed) return;

    const xMove = evt.pageX
    const xDiff = xDownCoord - xMove;

    if (xDiff > 0) {
      if (xMove < noCoords.x + noCoords.width / 2) {
        const maxDiff = originalCoords.x - noCoords.x;
        setXMoveDiff(-maxDiff);
      }
      else {
        setXMoveDiff(-xDiff);
      }

      const noXCoords = [noCoords.x, noCoords.x + noCoords.width];
      if (xMove >= noXCoords[0] && xMove <= noXCoords[1]) {
        setSwipeAnswer(SWIPED_NO);
      }
    } else {
      if (xMove > yesCoords.x + yesCoords.width / 2) {
        const maxDiff = originalCoords.x - yesCoords.x;
        setXMoveDiff(-maxDiff);
      }
      else {
        setXMoveDiff(-xDiff);
      }

      const yesXCoords = [yesCoords.x, yesCoords.x + yesCoords.width];
      if (xMove >= yesXCoords[0] && xMove <= yesXCoords[1]) {
        setSwipeAnswer(SWIPED_YES);
      }
    }
  }

  const handleTouchMove = (evt) => {
    if (xDownCoord === null) return;

    const xMove = evt.touches[0].clientX;
    const xDiff = xDownCoord - xMove;

    if (xDiff > 0) {
      if (xMove < noCoords.x + noCoords.width / 2) {
        const maxDiff = originalCoords.x - noCoords.x;
        setXMoveDiff(-maxDiff);
      }
      else {
        setXMoveDiff(-xDiff);
      }

      const noXCoords = [noCoords.x, noCoords.x + noCoords.width];
      if (xMove >= noXCoords[0] && xMove <= noXCoords[1]) {
        setSwipeAnswer(SWIPED_NO);
      }
    } else {
      if (xMove > yesCoords.x + yesCoords.width / 2) {
        const maxDiff = originalCoords.x - yesCoords.x;
        setXMoveDiff(-maxDiff);
      }
      else {
        setXMoveDiff(-xDiff);
      }

      const yesXCoords = [yesCoords.x, yesCoords.x + yesCoords.width];
      if (xMove >= yesXCoords[0] && xMove <= yesXCoords[1]) {
        setSwipeAnswer(SWIPED_YES);
      }
    }
  }

  const handleTouchFinish = (_evt) => {
    /* reset values */
    if (swipeAnswer !== null) {
      if (swipeAnswer === SWIPED_NO) {
        console.log('swiped no');
        onSwipeNo();
      } else {
        console.log('swiped yes');
        onSwipeYes();
      }
    }
    setXMoveDiff(0);
    setXDownCoord(null);
    setSwipeAnswer(null);
  }

  const handleMouseUp = useCallback(
    (evt) => {
      evt.preventDefault();

      if (wasMousePressed) {
        setWasMousePressed(false);
        if (swipeAnswer !== null) {
          if (swipeAnswer === SWIPED_NO) {
            console.log('swiped no');
            onSwipeNo();
          } else {
            console.log('swiped yes');
            onSwipeYes();
          }
        }
        setXMoveDiff(0);
        setXDownCoord(null);
        setSwipeAnswer(null);
      }
    }, [swipeAnswer, wasMousePressed, onSwipeNo, onSwipeYes]
  )

  const setFootballCoords = () => {
    const footballElement = footballIconRef.current;
    setOriginalCoords(footballElement.getBoundingClientRect());
  }

  const setYesBtnCoords = () => {
    const yesElement = yesDivRef.current;
    setYesCoords(yesElement.getBoundingClientRect());
  }

  const setNoBtnCoords = () => {
    const noElement = noDivRef.current;
    setNoCoords(noElement.getBoundingClientRect());
  }

  useEffect(() => {
    // This had to be handed globally, otherwise this would not trigger
    // if the mouse was moved outside thw swipe ui component
    window.addEventListener('mouseup', handleMouseUp, false);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp, false);
    }
  }, [handleMouseUp])

  return (
    <div className={`d-flex justify-content-between align-items-center w-100 swipe-answer rounded-container py-1 px-3 ${horizontalMarginClass}`}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}>
      <div id="swipe-no-container" ref={noDivRef} className={`answer ${swipeAnswer === SWIPED_YES ? 'opacity-25' : ''}`}>
        <img src={isSpecial ? noSpecialAnswer : noAnswer} alt="No" onLoad={setNoBtnCoords} className="img-fluid" />
      </div>
      <img src={threeArrows} alt="Desliza a la izquierda" className={`mx-2 ${swipeAnswer === SWIPED_YES ? 'opacity-25' : ''}`} />
      <img src={football}
        className="swipe-ball"
        alt="Football icon to swipe"
        id="swipe-football"
        ref={footballIconRef}
        style={{ transform: `translateX(${xMoveDiff}px)` }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchFinish}
        onLoad={setFootballCoords} />
      <img src={threeArrows} alt="Desliza a la derecha" className={`mx-2 inverted ${swipeAnswer === SWIPED_NO ? 'opacity-25' : ''}`} />
      <div id="swipe-yes-container" ref={yesDivRef} className={`d-flex justify-content-end answer ${swipeAnswer === SWIPED_NO ? 'opacity-25' : ''}`}>
        <img src={isSpecial ? yesSpecialAnswer : yesAnswer} alt="Si" onLoad={setYesBtnCoords} className="img-fluid" />
      </div>
    </div>
  )
}

export default SwipeAnswer;
