import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL || 'http://localhost:8000/swipe_gol',
})


export function getRandomQuestions() {
  return instance.get('/questions/');
}

export function postQuestionAnswer(swipeGameId, questionId, chosenAnswer, timeLeft ) {
  return instance.post('/question_answer/' + swipeGameId, {
    id: questionId,
    chosen_answer: chosenAnswer,
    time_left: timeLeft
  });
}