import SwipeAnswer from "./SwipeAnswer";
import timerImg from '../assets/images/timer.svg';


function AskQuestion({ timer, questionText, questionNum, totalQuestions, swipedNo, swipedYes, showLoader }) {
  return (
    <div className="d-flex flex-column z-index-2 h-75 w-95">

      <div className="gradient-rounded-border-green rect-scooped-corners mx-4 my-2 h-100 shadow">
        <div className="box w-100 h-100">
          <div className="top-shine"></div>
          <div className="corner nw"></div>
          <div className="corner ne"></div>
          <div className="corner sw"></div>
          <div className="corner se"></div>
          <div className="d-flex flex-column justify-content-between align-items-center flex-grow-1 h-100 bg-white box-content">
            <div className="d-flex justify-content-center align-items-center fw-semibold badge-status-timer w-25 py-2 px-5 mt-3">
              <img src={timerImg} alt="Tiempo" />
              <span className="text-white fw-bold ms-1">00:{timer === 10 ? timer : `0${timer}`}</span>
            </div>
            <div className="question-text text-center">{questionText}</div>
            <div className="question-num-container">
              <div>Pregunta</div>
              <div className="mb-1">
                <span className="question-current">{questionNum}</span>/<span>{totalQuestions}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-2">
        {!showLoader && <SwipeAnswer onSwipeNo={swipedNo} onSwipeYes={swipedYes} />}
        {showLoader && (
          <div className="spinner-border text-light custom-spinner" role="status">
            <span className="visually-hidden">Enviando respuesta...</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default AskQuestion;
