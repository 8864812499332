import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import backArrow from '../assets/images/back-arrow.svg';
import firstPlace from '../assets/images/first-place.svg';
import secondPlace from '../assets/images/second-place.svg';
import thirdPlace from '../assets/images/third-place.svg';
import facebook from '../assets/images/facebook.svg';
import twitter from '../assets/images/twitter.svg';
import whatsapp from '../assets/images/whatsapp.svg';
import { getLeaderboard } from "../utilities/endpoints/leaderboard_endpoints";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { isGamesActive } from "../utilities/activeness";


function LeaderboardView({ nickname, email, resetMatch }) {
  const baseUrl = window.location.origin.toString();
  const shareQuote = "Crees saber todo del mundial? ¡Juega Swipe Gol para competir y ganar premios diarios!";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);

  const newGame = () => {
    resetMatch();
    navigate('/');
  }

  useEffect(() => {
    if (!isGamesActive()) {
      navigate('/');
    }
  })

  useEffect(() => {
    getLeaderboard().then(resp => {
      const topPlayers = resp.data;
      setPlayers(topPlayers);
      setLoading(false);
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
  }, [])
  

  return (
    <div className="leaderboard h-100">
      <div className="leaderboard-bg"></div>
      <div className="leaderboard-bottom-bg"></div>

      <div className="h-100 justify-content-center d-flex align-items-stretch">
        <div className="w-100 d-flex flex-column align-items-center text-center text-white my-4 my-sm-5 z-index-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <img src={backArrow} alt="Atrás" onClick={newGame} />
              </div>
              <div className="col">
                <p className="text-uppercase swipe-gol-header">
                  <span>SWIPE</span>
                  <span className="text-gradient-special ms-2">GOL</span>
                </p>
              </div>
              <div className="col"></div>
            </div>
          </div>

          <div className="header fw-semibold mb-5">Leaderboard</div>

          <div className={`w-90 d-flex flex-column justify-content-${players.length < 10 ? "start" : "between"} align-items-stretch flex-grow-1 my-5"`}>
            {loading && (
              <div className="my-5">
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Cargando...</span>
                </div>
              </div>
            )}
            {!loading && players.map((player, i) => {
              const isYou = nickname === player.swipe_code__nickname
              const baseClass = isYou ? 'you' : (i > 2 ? 'other-player' : '');

              return (
                <div className={`d-flex justify-content-between align-items-center ${players.length < 10 ? "my-2" : ""} player ${baseClass}`} key={i}>
                  <div className="me-2 leaderboard-trophy">
                    {i === 0 && <img src={firstPlace} alt="Primer lugar" />}
                    {i === 1 && <img src={secondPlace} alt="Segundo lugar" />}
                    {i === 2 && <img src={thirdPlace} alt="Tercer lugar" />}
                  </div>
                  <div className={`flex-grow-1 text-start ${isYou ? 'text-decoration-underline' : ''}`}>
                    {i + 1}. {player.swipe_code__nickname}{isYou ? " (Tú)" : ""}
                  </div>
                  <div>
                    <span>{player.score}</span>
                    <sup className="points">pts</sup>
                  </div>
                </div>
              )}
            )}
          </div>

          <div className="w-90 mt-3">
            <div className="swipe-btn swipe-yellow-btn mb-4" onClick={newGame}>
              <div className="inner text-uppercase">VOLVER A JUGAR</div>
              <div className="bottom-shine"></div>
            </div>
            <div className="d-flex justify-content-between align-items-center share-footer">
              <div className="text-start share-with-friends">
                Comparte para competir con amigos.
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <FacebookShareButton url={baseUrl} quote={shareQuote}
                  className="social-media-icon" children={<img src={facebook} alt="Compartir en Facebook" />} />
                <TwitterShareButton url={baseUrl} title={shareQuote}
                   className="social-media-icon mx-3" children={<img src={twitter} alt="Compartir en Twitter" />} />
                <WhatsappShareButton url={baseUrl} title={shareQuote}
                  className="social-media-icon" children={<img src={whatsapp} alt="Compartir en WhatsApp" />} />
              </div>
            </div>
          </div>
          
        </div>  
      </div>
    </div>
  )
}

export default LeaderboardView
