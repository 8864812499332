import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import { CODE_COOKIE } from "../App";
import SwipeAnswer from "./SwipeAnswer";
import { generateSwipeGame } from "../utilities/endpoints/swipe_code_endpoints";
import { MAX_NUM_QUESTIONS } from "../utilities/constants";
import timerImg from '../assets/images/timer.svg';
import demoSwipe from '../assets/images/instructions-swipe-demo.png';
import ReactGA from 'react-ga4';
import EventTags from '../utilities/google_analytics_event_tags';


function InstructionsModal({ show, code, updateCookie, toggleShow, setSwipeGameId }) {
  const navigate = useNavigate();
  const [generatingGame, setGeneratingGame] = useState(false);

  const createGame = () => {
    setGeneratingGame(true);
    generateSwipeGame().then(resp => {
      const newCode = resp.data.swipe_code_uuid;
      const newGameId = resp.data.swipe_game_uuid;
      updateCookie(CODE_COOKIE, newCode);
      setSwipeGameId(newGameId);
      setGeneratingGame(false);
      ReactGA.event(EventTags.new_code)
      ReactGA.event(EventTags.new_game);
      navigate('/juego');
    }).catch(err => {
      console.log(err);
      setGeneratingGame(false);
    })
  }

  return (
    <Modal show={show} centered contentClassName="modal-content-bigger modal-gradient-purple-bg modal-transparent-border">
      <Modal.Header className="py-4 text-center">
        <Modal.Title className="text-white w-100">Instrucciones</Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-body-scroll modal-instructions-text text-white">
        <p>1. Serás presentado con {MAX_NUM_QUESTIONS} preguntas de verdadero/falso.</p>
        <div className="d-flex justify-content-around align-items-center w-100 swipe-answer rounded-container py-1 px-3 mb-3" style={{height: '70px'}}>
          <span>Falso</span>
          <span>verdadero</span>
        </div>

        <p>2. Desliza la pelota hacia la derecha para eligir ‘Si’, y desliza la pelota hacia la izquierda para eligir ‘No’.</p>
        <div className="d-flex justify-content-center align-items-center w-100">
          <img src={demoSwipe} alt="Swipe Demo" className="img-fluid mb-3" />
        </div>

        <p>3. Mientras más rápido respondas correctamente, más puntos ganas.</p>
        <div className="d-flex justify-content-center align-items-center questions mb-3">
          <div className="d-flex justify-content-center align-items-center fw-semibold badge-status-timer w-30 py-2">
            <img src={timerImg} alt="Tiempo" />
            <span className="text-white fw-bold ms-1">00:10</span>
          </div>
        </div>

        <p className="mb-3">4. Al fin de la partida, registra tus puntos para participar en una premiación diaria al high score!</p>
        <div className="instructions-start d-flex flex-column justify-content-center align-items-center text-white fw-semibold px-5 py-2 mb-3">
          <div className="text-center mb-4">Veamos si estás listo para jugar.</div>
          <div>¿Empezamos?</div>
        </div>
        <div className="d-flex justify-content-center align-items-center instructions-swipe">
          {!generatingGame && <SwipeAnswer onSwipeNo={toggleShow} onSwipeYes={createGame} horizontalMarginClass="mx-0" />}
          {generatingGame && (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Iniciando juego</span>
            </div>
          )}
        </div>
      </Modal.Body>

      <div className="darken-bottom position-absolute"></div>
    </Modal>
  )
}

export default InstructionsModal;
