import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AskQuestion from "../components/AskQuestion";
import AskSpecialQuestion from "../components/AskSpecialQuestion";
import CorrectAnswer from "../components/CorrectAnswer";
import IncorrectAnswer from "../components/IncorrectAnswer";
import { getRandomQuestions, postQuestionAnswer } from "../utilities/endpoints/questions_endpoints";
import codereLogo from '../assets/images/codere-yak-logo.png';
import backArrow from '../assets/images/back-arrow.svg';
import swipeGolLogo from '../assets/images/logo-swipe-gol.png';
import { SPECIAL_QUESTION_1_NUM, SPECIAL_QUESTION_2_NUM } from "../utilities/constants";
import airbrake from '../utilities/airbrake';
import ReactGA from 'react-ga4';
import EventTags from '../utilities/google_analytics_event_tags';
import { isGamesActive } from "../utilities/activeness";


const MAX_QUESTION_TIMER = 10;

function QuestionsView({ score, swipeGameId, accumScore, resetMatch }) {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [questionCount, setQuestionCount] = useState(0);
  const [timer, setTimer] = useState(MAX_QUESTION_TIMER);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [addedScore, setAddedScore] = useState(null);
  const [postingQuestion, setPostingQuestion] = useState(false);
  const [userSelectedAnswer, setUserSelectedAnswer] = useState(false);

  const newGame = () => {
    resetMatch();
    navigate('/');
  }

  const nextQuestion = useCallback(() => {
    if (questionCount + 1 < questions.length) {
      setQuestionCount((lastQuestionCount) => lastQuestionCount + 1);
      setTimer(MAX_QUESTION_TIMER);
      setIsAnswerCorrect(null);
      setAddedScore(null);
      setUserSelectedAnswer(false);
    } else {
      ReactGA.event(EventTags.answered_all);
      navigate("/registro");
    }
  }, [navigate, questionCount, questions.length]);

  const verifyAnswer = (answer) => {
    const question = questions[questionCount];
    setPostingQuestion(true);
    postQuestionAnswer(swipeGameId, question.id, answer, timer).then(_resp => {
      if (answer === question.answer) {
        if (questionCount === SPECIAL_QUESTION_1_NUM - 1) { // Question 7 (index would be 6)
          setAddedScore(timer * 2);
        }
        else if (questionCount === SPECIAL_QUESTION_2_NUM - 1) { // Question 14 (index would be 13)
          setAddedScore(timer * 3);
        }
        else {
          setAddedScore(timer);
        }
        setIsAnswerCorrect(true);
        ReactGA.event(EventTags.answered_correctly);
      } else {
        setAddedScore(-2);
        setIsAnswerCorrect(false);
        ReactGA.event(EventTags.answered_incorrectly);
      }
      setPostingQuestion(false);
      setUserSelectedAnswer(true)
    }).catch(err => {
      console.log(err, 'checkedAnswer');
      if (airbrake) airbrake.notify(`verifyAnswer ${err} - swipeGameId: ${swipeGameId} - questionCount: ${questionCount}`);
      alert('Ocurrio un error, intenta más tárde');
      navigate('/');
    })
  };

  const swipedNo = () => {
    verifyAnswer(false)
  }

  const swipedYes = () => {
    verifyAnswer(true)
  }

  useEffect(() => {
    if (!isGamesActive()) {
      navigate('/');
    }
  })

  useEffect(() => {
    getRandomQuestions().then(resp => {
      const apiQuestions = resp.data.map(question =>  {
        return { chosen_answer: null, ...question }
      })
      setQuestions(apiQuestions);
      setLoadingQuestions(false);
    }).catch(err => {
      console.log(err, 'null answer');
      setQuestions([]);
      setLoadingQuestions(false);
    })
  }, []);

  useEffect(() => {
    if (timer === 0) {
      const question = questions[questionCount];
      setPostingQuestion(true);
      postQuestionAnswer(swipeGameId, question.id, null, timer).then(_resp => {
        setAddedScore(-2);
        setIsAnswerCorrect(false);
        setPostingQuestion(false);
      }).catch(err => {
        console.log(err);
        if (airbrake) airbrake.notify(`NO SELECTED ANSWER ${err} - swipeGameId: ${swipeGameId} - questionCount: ${questionCount}`);
        alert('Ocurrio un error, intenta más tárde');
        navigate('/');
      })
    }
    const countAux = timer > 0 && !userSelectedAnswer && setInterval(() => setTimer((lastTimer) => lastTimer - 1), 1000);
    return () => clearInterval(countAux);
  }, [timer, accumScore, questions, questionCount, swipeGameId, navigate, userSelectedAnswer]);

  useEffect(() => {
    if (addedScore !== null) accumScore(addedScore);
  }, [addedScore, accumScore]);


  const isSpecialQuestion = (questionCount === SPECIAL_QUESTION_1_NUM - 1 || questionCount === SPECIAL_QUESTION_2_NUM - 1)
  const bottomBgColor = isSpecialQuestion && isAnswerCorrect === null ?
    'special'
    : (isAnswerCorrect === null ?
        'purple'
        : (isAnswerCorrect) ?
          'green'
          : 'red');

  return (
    <>
      <div className={`questions-field ${isSpecialQuestion ? 'special':''}`}></div>
      <div className={`rect-bottom rect-bottom-${bottomBgColor} z-index-1`}></div>
      <div className="questions-white-circles z-index-1"></div>
      <div className="h-100 d-flex justify-content-center align-items-stretch questions">
        <div className="w-100 d-flex flex-column align-items-center text-center mt-4 mt-sm-5">
          <div className="container-fluid z-index-2 mb-2">
            <div className="row">
              <div className="col">
                <img src={backArrow} alt="Atrás" onClick={newGame} />
              </div>
              <div className="col-8">
                <img src={swipeGolLogo} alt="Logo Swipe Gol - Qatar 2022" className="img-fluid" />
              </div>
              <div className="col"></div>
            </div>
          </div>

          <div className="w-95 z-index-1 mb-4 mb-sm-5 fw-semibold">
            <div className="d-flex mx-4 justify-content-between align-items-center">
              <img src={codereLogo} alt="Presentado por Codere" className="w-45 img-fluid" />
              <div className="w-50 d-flex justify-content-end">
                {addedScore !== null && addedScore > 0 &&
                  <div className="mw-50 added-score added-score-correct">+{addedScore}{`${Math.abs(addedScore) > 1 ? 'pts' : 'pt'}`}</div>}
                {addedScore !== null && addedScore < 0 &&
                  <div className="mw-50 added-score added-score-incorrect">{addedScore}{`${Math.abs(addedScore) > 1 ? 'pts' : 'pt'}`}</div>}
                <div className="ms-1 mw-50 justify-self-end total-score text-white">{score}pts</div>
              </div>
            </div>
          </div>
          {loadingQuestions && <p className="text-white z-index-2">Cargando preguntas...</p>}
          {questions.length > 0 && !loadingQuestions && !addedScore && !(questionCount === SPECIAL_QUESTION_1_NUM - 1 || questionCount === SPECIAL_QUESTION_2_NUM - 1) && (
            <AskQuestion timer={timer}
              questionText={questions[questionCount].text}
              questionNum={questionCount + 1}
              totalQuestions={questions.length}
              swipedNo={swipedNo}
              swipedYes={swipedYes}
              showLoader={postingQuestion} />
          )}
          {questions.length > 0 && !loadingQuestions && !addedScore && (questionCount === SPECIAL_QUESTION_1_NUM - 1 || questionCount === SPECIAL_QUESTION_2_NUM - 1) && (
            <AskSpecialQuestion timer={timer}
              questionText={questions[questionCount].text}
              questionNum={questionCount + 1}
              swipedNo={swipedNo}
              swipedYes={swipedYes}
              showLoader={postingQuestion} />
          )}
          {!postingQuestion && questions.length > 0 && !loadingQuestions && addedScore && isAnswerCorrect === false && (
            <IncorrectAnswer
              questionNum={questionCount + 1}
              totalQuestions={questions.length}
              questionText={questions[questionCount].text}
              proceed={nextQuestion} />
          )}
          {!postingQuestion && questions.length > 0 && !loadingQuestions && addedScore && isAnswerCorrect === true && (
            <CorrectAnswer
              questionNum={questionCount + 1}
              totalQuestions={questions.length}
              questionText={questions[questionCount].text}
              proceed={nextQuestion} />
          )}
        </div>
      </div>
    </>
  );
}

export default QuestionsView;
