
export function setCookie(cookieName, cookieVal) {
  const d = new Date();
  const midnight = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);
  let expires = "expires=" + midnight.toUTCString();
  document.cookie = cookieName + "=" + cookieVal + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');

  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function isNullOrEmpty(str) {
  return str === null || str === '' || str === undefined
}