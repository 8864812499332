
function Prize({ heightClass='h-25', widthClass='w-100', extraClasses='' }) {
  return (
    <div className={`d-flex flex-column justify-content-center align-items-center ${widthClass} ${heightClass} ${extraClasses} register-prize-container text-black`}>
      <div className="title position-relative">cinépolis</div>
      <div className="transparent-border w-80 my-2"></div>
      <div className="description">2 Boletos de Cinépolis VIP</div>
    </div>
  )
}

export default Prize;
