import { CloseButton, Modal } from "react-bootstrap";
import Prize from "./Prize";

function PrizeModal({ show, onHide, onClose }) {

  return (
    <Modal show={show} onHide={onHide} centered contentClassName="prize-modal" backdropClassName="prize-modal-backdrop">
      <Modal.Body className="text-white text-center d-flex flex-column justify-content-between align-items-center">
        <div className="align-self-end d-flex justify-content-center align-items-center close-button z-index-3" onClick={onClose}>
          <CloseButton aria-label="Cerrar" className="btn-close-prize"/>
        </div>
        <div className="prize-circles position-absolute top-0 bottom-0 left-0 right-0 w-100 h-100"></div>
        <div className="header p-3 mb-2">
          <p className="mx-auto w-75">¡Consigue el premio del día!</p>
        </div>
        <Prize extraClasses="z-index-3" heightClass='h-50' widthClass='w-100' />
        <div className="subheader w-75">Juega, suma puntos y participa por premios diarios en Swipe Gol</div>
      </Modal.Body>
    </Modal>
  )
}

export default PrizeModal;