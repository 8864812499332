import { Notifier } from '@airbrake/browser';

let airbrake = null;


if (process.env.REACT_APP_AIRBRAKE_ID && process.env.REACT_APP_AIRBRAKE_KEY) {
  airbrake = new Notifier({
    projectId: process.env.REACT_APP_AIRBRAKE_ID,
    projectKey: process.env.REACT_APP_AIRBRAKE_KEY,
    environment: process.env.NODE_ENV,
  });
}

export default airbrake;