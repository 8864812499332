import olaMediaLogo from '../../assets/images/ola-media-logo.svg';
import codereLogo from '../../assets/images/codere-yak-logo.png';
import swipeGolLogo from '../../assets/images/logo-swipe-gol.png';
import ballAndFlag from '../../assets/images/balon-items-home.png';
import { useEffect, useState } from "react";
import InstructionsModal from "../../components/InstructionsModal";
import PrizeModal from '../../components/PrizeModal';
import { isNullOrEmpty } from '../../utilities/cookies';
import { isGamesActive } from '../../utilities/activeness';

function PlayView({ code, updateCookie, setSwipeGameId }) {
  const [showInstructions, setShowInstructions] = useState(false);
  const [showPrize, setShowPrize] = useState(false);

  const toggleShowInstructions = () => {
    setShowInstructions(prevShow => !prevShow);
  }

  const toggleShowPrize = () => {
    setShowPrize(prevShow => !prevShow);
  }

  const onClose = (e) => {
    e.preventDefault()
    setShowPrize(false);
  }

  useEffect(() => {
    if (isNullOrEmpty(code) && isGamesActive()) {
      var autoCloseShowPrize = null
      const timeout = setTimeout(
        () => {
          setShowPrize(true)
          autoCloseShowPrize = setTimeout(
            () => setShowPrize(false),
            5000
          )
        },
        1000
      )
  
      return () => {
        clearTimeout(timeout);
        clearTimeout(autoCloseShowPrize);
      }
    }
  }, [code])

  return (
    <>
      <div className="field"></div>
      <div className="red-rect z-index-2"></div>
      <div className="h-100 d-flex justify-content-center align-items-stretch bg-dark bg-start">
        <div className="d-flex w-100 flex-column justify-content-between align-items-center text-center mt-4 my-sm-5">
          <img src={olaMediaLogo} alt="Logo Ola Media" className="mx-auto ola-media-logo z-index-2 mb-2 mb-sm-0"/>
          <img src={swipeGolLogo} alt="Logo Swipe Gol - Qatar 2022" className="play-swipe-logo z-index-2" />
          <div className="flag-and-ball z-index-2 mb-0 mb-sm-5 mt-3">
            <img src={ballAndFlag} alt="Balón y Bandera" />
          </div>
          <div className="sponsor w-100 z-index-3">Presentado por:</div>
          <img src={codereLogo} alt="Presentado por Codere" className="codere-logo mb-2 mb-sm-0 z-index-3" />
          {isGamesActive() &&
            <div className="my-0 my-sm-5 z-index-3 play-button mb-5" onClick={toggleShowInstructions} />
          }
          {!isGamesActive() &&
            <p className="my-0 my-sm-5 z-index-3 mb-5 thx-playing">¡Gracias por haber participado!</p>
          }
          <InstructionsModal
            show={showInstructions}
            code={code}
            updateCookie={updateCookie}
            setSwipeGameId={setSwipeGameId}
            toggleShow={toggleShowInstructions} />
        </div>
      </div>
      <PrizeModal show={showPrize} onClose={onClose} onHide={toggleShowPrize} />
    </>
  )
}

export default PlayView
